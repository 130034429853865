<template>
  <div>
    <div v-if="isLoadingOrders" class="text-center my-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-else>
      <b-table
        :items="orders"
        :fields="fields"
        small
        responsive
        :tbody-tr-class="rowClass"
        id="subscriptions-marketplace-orders-table"
        @row-clicked="onRowClicked"
      >
        <template v-slot:cell(account_name)="data">
          {{ data.item.account_name }}
        </template>

        <template v-slot:cell(cascade_bidder_id)="data">
          <b-link :to="`/subscriptions/${data.item.subscription.uuid}/marketplace`">
            {{ data.item.subscription.cascade_bidder_id }}
          </b-link>
        </template>

        <template v-slot:cell(actions)="data">
          <b-button
            v-if="data.item.status === 'accepted'"
            variant="link"
            class="p-0"
            @click.stop="openCancelModal(data.item)"
          >
            <b-icon icon="x-circle" variant="danger"></b-icon>
          </b-button>
        </template>
      </b-table>

      <update-order-modal
        v-if="selectedOrder && marketplaceEpochs.length"
        :order="selectedOrder"
        :marketplace-epochs="marketplaceEpochs"
        v-on:modal-hide="selectedOrder = null"
        v-on:order-deleted="onOrderDeleted"
        v-on:order-cancelled="onOrderCancelled"
      />
    </div>
  </div>
</template>

<script>
import updateOrderModal from './update-order-modal.vue';

export default {
  name: 'AdminMarketplaceOrdersTable',

  components: { updateOrderModal },

  props: {
    orders: {},
    marketplaceEpochs: {
      type: Array,
    },
    isLoadingOrders: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedOrder: null
    };
  },

  computed: {
    fields() {
      return [
        { key: 'account_name', label: 'Account' },
        { key: 'cascade_bidder_id', label: 'Bidder ID' },
        { key: 'requested_bandwidth', label: 'PPS' },
        { key: 'max_bid', label: 'Max Bid' },
        { key: 'beginning_marketplace_epoch_number', label: 'Starts' },
        { key: 'ending_marketplace_epoch_number', label: 'Ends' },
        { key: 'actions', label: '' },
      ];
    },
  },

  methods: {
    onRowClicked(item) {
      this.selectedOrder = item;
    },

    rowClass(item) {
      if (item.allocations_for_epoch.length) {
        return 'row-accepted';
      } else {
        return 'row-rejected';
      }
    },

    onOrderDeleted(uuid) {
      this.$emit('order-deleted', uuid)
    },

    onOrderCancelled(order) {
      this.$emit('order-cancelled', order)
    },
  },
};
</script>

<style>
#subscriptions-marketplace-orders-table tr:hover {
  cursor: pointer;
}

.row-accepted {
  background-color: #e6ffed; /* Light green */
}

.row-rejected {
  background-color: #ffe6e6; /* Light red */
}
</style>