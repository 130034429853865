<template>
  <div>
    <b-modal
      id="order-modal"
      :visible="showModal"
      title="Order Details"
      @hide="onModalHide"
      centered
      ok-only
      ok-title="Close"
    >
      <div v-if="order">
        <p><strong>Bidder ID:</strong> {{ order.bidder_id }}</p>
        <p><strong>Requested Bandwidth:</strong> {{ order.requested_bandwidth }}</p>
        <p><strong>Max Bid Amount:</strong> {{ order.max_bid }}</p>
        <p><strong>Starts:</strong> {{ order.beginning_marketplace_epoch_number }}</p>
        <p><strong>Ends:</strong> {{ order.ending_marketplace_epoch?.epoch || 'N/A' }}</p>

        <!-- Delete Order Section -->
        <div v-if="order.has_never_become_confirmed">
          <hr />
          <p>This order has not been confirmed yet so it can be deleted</p>
          <transition name="fade" mode="out-in">
            <div v-if="!isDeleting" key="delete-button">
              <b-button variant="danger" @click="isDeleting = true">
                Delete Order
              </b-button>
            </div>
            <div v-else key="confirm-delete-buttons" class="btn-group">
              <b-button size="sm" variant="danger" @click="confirmDeleteOrder">
                Yes, Delete
              </b-button>
              <b-button size="sm" variant="secondary" @click="isDeleting = false">
                Cancel
              </b-button>
            </div>
          </transition>
        </div>

        <!-- Cancel Order Section -->
        <div v-if="showCancelOrderForm">
          <hr />
          <h4>Cancel order</h4>
          <p>
            You are about to cancel the order for Bidder ID
            <strong>{{ order.bidder_id }}</strong>.
          </p>
          <p>Your order will remain active until the end of the selected epoch.</p>

          <b-alert v-if="errors.general" variant="danger">
            {{ errors.general }}
          </b-alert>
          <b-form @submit.prevent="onCancelOrderSubmit">
            <b-form-group
              label="Ending Epoch"
              label-for="ending-epoch-input"
              :state="!cancelErrors.ending_epoch"
            >
              <b-form-input
                id="ending-epoch-input"
                v-model.number="cancelForm.ending_epoch"
                required
              />
              <b-form-invalid-feedback :state="!cancelErrors.ending_epoch">
                {{ cancelErrors.ending_epoch }}
              </b-form-invalid-feedback>
            </b-form-group>

            <transition name="fade" mode="out-in">
              <div v-if="!isCancelling" key="cancel-order-button">
                <b-button type="submit" variant="danger">
                  Cancel Order
                </b-button>
              </div>
              <div v-else key="confirm-cancel-buttons" class="btn-group">
                <b-button type="button" variant="danger" @click="confirmCancelOrder">
                  Yes, cancel
                </b-button>
                <b-button type="button" variant="secondary" @click="isCancelling = false">
                  No, don't cancel
                </b-button>
              </div>
            </transition>
          </b-form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'UpdateOrderModal',

  props: {
    order: {
      type: Object,
      required: false,
    },
    marketplaceEpochs: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      cancelForm: {
        ending_epoch: null,
      },
      cancelErrors: {},
      errors: {},
      isDeleting: false,
      isCancelling: false,
    };
  },

  created() {
    if (this.order.beginning_marketplace_epoch_number < this.currentEpochNumber) {
      this.cancelForm.ending_epoch = this.currentEpochNumber;
    } else {
      this.cancelForm.ending_epoch = this.order.beginning_marketplace_epoch_number;
    }
  },

  watch: {
    'cancelForm.ending_epoch'(newVal) {
      if (newVal < this.currentEpochNumber) {
        this.cancelErrors.ending_epoch = `Ending Epoch must be greater than or equal to the current epoch (${this.currentEpochNumber})`;
      } else {
        this.cancelErrors.ending_epoch = null; // Clear the error if the value is valid
      }
    },
  },

  computed: {
    currentEpoch() {
      return this.marketplaceEpochs.find((e) => e.epoch_info && e.epoch_info.is_current_epoch);
    },
    currentEpochNumber() {
      return this.currentEpoch?.epoch;
    },
    showModal() {
      return !!this.order;
    },
    showCancelOrderForm() {
      return !this.order.ending_marketplace_epoch || this.order.ending_marketplace_epoch_number > this.currentEpochNumber;
    },
  },

  methods: {
    onModalHide() {
      this.$emit('modal-hide');
    },

    onCancelOrderSubmit() {
      this.cancelErrors = {};

      // Validate that endingEpoch is greater than or equal to currentEpoch
      if (this.cancelForm.ending_epoch < this.currentEpochNumber) {
        this.cancelErrors.ending_epoch = 'Ending Epoch must be greater than or equal to the current epoch';
        return;
      }

      this.isCancelling = true;
    },

    confirmDeleteOrder() {
      const uuid = this.order.uuid;

      http
        .delete(`subscription_marketplace_orders/${this.order.uuid}`)
        .then(() => {
          this.$root.$bvToast.toast(`You successfully deleted the order`, {
            title: 'Success',
            variant: 'default',
          });

          this.$emit('order-deleted', uuid);
          this.$bvModal.hide('order-modal');
        })
        .catch((error) => {
          console.error('Failed to delete order:', error);
          this.$bvToast.toast('Failed to delete the order', {
            title: 'Error',
            variant: 'danger',
          });
          this.isDeleting = false; // Reset the deleting state
        });
    },

    confirmCancelOrder() {
      this.cancelErrors = {};

      const payload = {
        subscription_marketplace_order: {
          ending_epoch: this.cancelForm.ending_epoch,
        },
      };

      http
        .put(`subscription_marketplace_orders/${this.order.uuid}/cancel`, payload)
        .then((response) => {
          this.$root.$bvToast.toast(`You successfully cancelled the order`, {
            title: 'Success',
            variant: 'default',
          });

          const order = response.data.subscription_marketplace_order;
          this.$emit('order-cancelled', order);
          this.$bvModal.hide('order-modal');
        })
        .catch((error) => {
          this.$bvToast.toast('Could not cancel order', {
            title: '',
            variant: 'danger',
          });
          this.isCancelling = false; // Reset the cancelling state
        });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>