import { render, staticRenderFns } from "./update-marketplace-epoch-form.vue?vue&type=template&id=6bda9742&scoped=true"
import script from "./update-marketplace-epoch-form.vue?vue&type=script&lang=js"
export * from "./update-marketplace-epoch-form.vue?vue&type=script&lang=js"
import style0 from "./update-marketplace-epoch-form.vue?vue&type=style&index=0&id=6bda9742&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bda9742",
  null
  
)

export default component.exports