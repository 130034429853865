<template>
  <div>
    <b-alert
      v-if="successMessage"
      variant="success"
      dismissible
      @dismissed="successMessage = null"
    >
      {{ successMessage }}
    </b-alert>

    <b-alert
      v-if="errorMessage"
      variant="danger"
      dismissible
      @dismissed="errorMessage = null"
    >
      {{ errorMessage }}
    </b-alert>

    <b-form @submit.prevent="updateMarketplaceEpoch">
      <b-form-group label="Current Available Bandwidth" label-for="current-available-bandwidth">
        <b-form-input
          id="current-available-bandwidth"
          v-model.number="form.current_available_bandwidth"
          type="number"
          min="0"
          required
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.current_available_bandwidth">
          {{ errors.current_available_bandwidth }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Current Floor" label-for="current-floor">
        <b-form-input
          id="current-floor"
          v-model.number="form.current_floor"
          type="number"
          min="0"
          step="0.01"
          required
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.current_floor">
          {{ errors.current_floor }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Price Incrementation" label-for="price-incrementation">
        <b-form-input
          id="price-incrementation"
          v-model.number="form.price_incrementation"
          type="number"
          min="0"
          step="0.01"
          required
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.price_incrementation">
          {{ errors.price_incrementation }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Orders Closed At" label-for="orders-closed-at">
        <b-form-datepicker
          id="orders-closed-at"
          v-model="form.orders_closed_at"
          :state="!errors.orders_closed_at"
        ></b-form-datepicker>
        <b-form-invalid-feedback :state="!errors.orders_closed_at">
          {{ errors.orders_closed_at }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary" :disabled="isSubmitting">
        Update Epoch
      </b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    marketplaceEpoch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
      form: {
        current_available_bandwidth: null,
        current_floor: null,
        price_incrementation: null,
        orders_closed_at: null,
      },
      errors: {},
      successMessage: null,
      errorMessage: null,
    };
  },
  created() {
    this.initializeForm();
  },
  methods: {
    initializeForm() {
      // Create a local copy of the marketplaceEpoch data to avoid mutating props
      this.form.current_available_bandwidth = this.marketplaceEpoch.current_available_bandwidth;
      this.form.current_floor = this.marketplaceEpoch.current_floor;
      this.form.price_incrementation = this.marketplaceEpoch.price_incrementation;
      this.form.orders_closed_at = this.marketplaceEpoch.orders_closed_at;
    },
    updateMarketplaceEpoch() {
      this.isSubmitting = true;
      this.errors = {};
      this.successMessage = null;
      this.errorMessage = null;

      const apiUrl = `/api/v1/marketplace_epochs/${this.marketplaceEpoch.epoch}`;

      const payload = {
        marketplace_epoch: {
          current_available_bandwidth: this.form.current_available_bandwidth,
          current_floor: this.form.current_floor,
          price_incrementation: this.form.price_incrementation,
          orders_closed_at: this.form.orders_closed_at,
        },
      };

      this.$http
        .patch(apiUrl, payload)
        .then((response) => {
          this.successMessage = 'Marketplace Epoch updated successfully.';
          // Optionally, emit an event to notify the parent component
          this.$emit('epoch-updated', response.data.marketplace_epoch);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            const data = error.response.data;
            if (data.errors) {
              this.processErrors(data.errors);
            } else if (data.error) {
              this.errorMessage = data.error;
            } else {
              this.errorMessage = 'An error occurred while updating the epoch.';
            }
          } else {
            this.errorMessage = 'An error occurred while updating the epoch.';
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    processErrors(errors) {
      this.errors = {};
      if (Array.isArray(errors)) {
        // Assuming errors is an array of strings
        this.errorMessage = errors.join(' ');
      } else {
        // Assuming errors is an object with field-specific errors
        Object.keys(errors).forEach((field) => {
          this.errors[field] = errors[field][0];
        });
      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>