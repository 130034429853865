<template>
  <div class="sidebar">
    <div>
      <div>
        <router-link to="/" class="navbar-brand nav-logo" exact>
          <triton-logo :height="60" />
        </router-link>
      </div>

      <div class="sidebar-links justify-space-between">

        <!--  -->
        <!-- admin sidebar -->
        <!--  -->

        <div v-if="currentUser?.is_admin" class="links-container" d-flex>
          <router-link to="/admins/accounts" class="nav-links d-flex align-items-center">
            <b-icon icon="wallet-fill" class="icon mr-2" />
            <span>Accounts</span>
          </router-link>

          <router-link to="/admins/users" class="nav-links d-flex align-items-center">
            <b-icon icon="person-lines-fill" class="icon mr-2" />
            <span>Users</span>
          </router-link>

          <router-link to="/admins/subscriptions" class="nav-links d-flex align-items-center">
            <b-icon icon="file-earmark-medical" class="icon mr-2" />
            <span>Subscriptions</span>
          </router-link>

          <router-link to="/admins" class="nav-links d-flex align-items-center" exact>
            <b-icon icon="speedometer" class="icon mr-2" />
            <span>Admin</span>
          </router-link>

          <router-link to="/admins/marketplace" class="nav-links d-flex align-items-center">
            <b-icon icon="graph-up" class="icon mr-2" />
            <span>(Admin) Cascade Marketplace</span>
          </router-link>

          <router-link to="/coinflow-webhook-requests" class="nav-links d-flex align-items-center" exact>
            <b-icon icon="list-task" class="icon mr-2" />
            <span>Coinflow Webhooks</span>
          </router-link>

          <router-link to="/helio-webhook-events" class="nav-links d-flex align-items-center" exact>
            <b-icon icon="list-task" class="icon mr-2" />
            <span>Helio Webhooks</span>
          </router-link>

          <router-link :to="{ name: 'manageUserRpc', params: { uuid: currentUser.uuid } }" class="nav-links d-flex align-items-center">
            <b-icon icon="plug-fill" class="icon mr-2" />
            <span>RPC</span>
          </router-link>

          <router-link to="/admins/reporting" class="nav-links d-flex align-items-center">
            <b-icon icon="bullseye" class="icon mr-2" />
            <span>Reporting</span>
          </router-link>
        </div>

        <!--  -->
        <!-- non admin sidebar -->
        <!--  -->

        <div v-else class="links-container" d-flex>
          <router-link :to="{ name: 'manageUserRpc', params: { uuid: currentUser.uuid } }" class="nav-links d-flex align-items-center">
            <b-icon icon="speedometer" class="icon mr-2" />
            <span>RPC</span>
          </router-link>

          <div v-if="currentUser.accounts.length" class="mt-3">Accounts</div>
          <div v-for="account in currentUser.accounts" :key="account.uuid">
            <router-link
              :to="{ name: 'editAccount', params: { uuid: account.uuid } }"
              class="nav-links d-flex "
              exact
            >
              <b-icon icon="circle-fill" class="icon-dot" />
              <span>{{ account.name }}</span>
            </router-link>
          </div>

          <div v-if="currentUser.subscriptions.length" class="mt-3">Subscriptions</div>
          <div v-for="subscription in currentUser.subscriptions" :key="subscription.uuid">
            <router-link
              :to="{ name: 'editSubscription', params: { uuid: subscription.uuid } }"
              class="nav-links d-flex"
              exact
            >
              <b-icon icon="circle-fill" class="icon-dot" />
              <span>{{ subscription.name }}</span>
            </router-link>

            <div v-if="cascadeMarketplace(subscription)">
              <div class="my-2">
                <router-link
                  :to="{ name: 'marketplaceHome', params: { uuid: subscription.uuid } }"
                  class="d-flex align-items-center ml-3 p-2 nav-links"
                >
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-plane w-[40px] h-[40px] stroke-triton-fgd-1 svg-active-color mr-2">
                    <path
                      d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z"
                    />
                  </svg>
                  Cascade Marketplace
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="d-flex justify-content-center mb-3">
        <div class="w-100 d-flex justify-content-between">
          <hr class="divider-right align-self-center" />
          <hr class="divider-left align-self-center" />
        </div>
      </div>
      <div class="buttons-container mb-3">
        <button class="table-button" v-b-modal.manage-user-modal>
          <b-icon icon="gear-fill" />
          <span>Manage User</span>
        </button>

        <button class="table-button" @click="openSignOutConfirmation">
          <b-icon icon="box-arrow-in-right" />
          <span>Sign Out</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'sidebar',

  data() {
    return {
      accountUuid: null,
      subscriptionUuid: null,
    };
  },

  computed: {
    ...mapGetters('sessions', ['currentUser', 'hasAccountWithHelio']),
  },

  methods: {
    openSignOutConfirmation() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to sign out?', {
          okVariant: 'primary',
          okTitle: 'Yes',
        })
        .then((value) => {
          if (value === true) {
            this.$root.$bvToast.toast(`You successfully signed out`, {
              title: 'Signed out',
              variant: 'default',
              autoHideDelay: 100,
            });
            this.signOut();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async signOut() {
      this.$router.push('/users/sign-in');
      await this.$store.dispatch('sessions/signOut');
    },

    cascadeMarketplace(subscription) {
      return subscription.cascade_marketplace;
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s cubic-bezier(0.18, 0, 0.51, 0.99);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.nav-logo {
  overflow: hidden;
  width: 100%;
}
.icon {
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-top: 0px;
}
.icon-dot {
  width: 7px;
  height: 7px;
  padding: 0px;
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.5) !important;
  margin-left: 5px;
  margin-right: 13px;
}
.router-link-active .icon-dot {
  color: #f606ff !important;
  box-shadow: 0 0 15px #f606ff, 0 0 5px #f606ff;
  border-radius: 50%;
}

.router-link-active .svg-active-color {
  color: #f606ff !important;
  filter: drop-shadow(3px 3px 4px #f606ff);
}

.router-link-active::before {
  content: '';
  position: absolute;
  left: -22px;
  top: 38%;
  bottom: 38%;
  height: 10px;
  width: 5px;
  background-color: #f606ff;
  box-shadow: 0 0 15px #f606ff, 0 0 5px #f606ff;
  border-radius: 3px;
}
.router-link-active {
  background: #f1f3f7;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.07);
}
.router-link-active .icon {
  color: #3511ffd8;
}

.table-button {
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
  width: auto;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  padding: 12px;
  text-decoration: none;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0 1px 1px transparent;
  margin-bottom: 0.6rem;
  transition: background-color 0.2s ease, transform 0.2s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.table-button:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.07);
}

.table-button:active {
  text-decoration: none;
  transform: scale(0.98);
  background: #e0e7f0;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.06), 0 0px 0px rgba(0, 0, 0, 0.08);
}

h6 {
  color: rgba(0, 0, 0, 0.5);
}
</style>
