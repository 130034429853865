<template>
  <div class="p-3 container-xxl">
    <div class="card mb-3">
      <div class="card-header bg-light py-3">
        <h2>(Admin) Cascade Marketplace</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-header bg-light py-2">
            <h4>Orders</h4>
            <p class="fs--1">Select an epoch on the right to filter orders for it</p>
          </div>
          <div class="card-body">
            <admin-marketplace-orders-table
              v-if="marketplaceEpochs.length"
              :orders="orders"
              :marketplace-epochs="marketplaceEpochs"
              :current-epoch="currentMarketplaceEpoch.epoch"
              :is-loading-orders="isLoadingOrders"
              v-on:order-cancelled="onOrderCancelled"
              v-on:order-deleted="onOrderDeleted"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="sticky-top">
          <div class="card">
            <div class="card-header bg-light py-2">
              <h4>Epochs</h4>
            </div>
            <div class="card-body">
              <marketplace-epochs-table
                v-if="marketplaceEpochs.length"
                :marketplace-epochs="marketplaceEpochs"
                v-on:epoch-selected="epochSelected"
                :admin-view="true"
              />
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-header bg-light py-2">
              <h4>Cascade Marketplace Configurations</h4>
              <p class="fs--1">
                Configure starting values for future marketplace epochs
              </p>
            </div>
            <div class="card-body">
              <marketplace-epochs-configurations-table />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters, mapActions } from 'vuex';
import { createConsumer } from '@rails/actioncable';

import marketplaceEpochsTable from './marketplace-epochs-table';
import adminMarketplaceOrdersTable from './admin-marketplace-orders-table';
import marketplaceEpochsConfigurationsTable from './marketplace-epochs-configurations-table';

export default {
  name: 'AdminMarketplaceHome',

  components: { marketplaceEpochsTable, adminMarketplaceOrdersTable, marketplaceEpochsConfigurationsTable },

  data() {
    return {
      orders: [],
      isLoadingOrders: false,
      selectedEpoch: null,
    };
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('marketplaceEpochs', [
      'marketplaceEpochs',
      'currentMarketplaceEpoch',
    ])
  },

  methods: {
    ...mapActions('marketplaceEpochs', ['fetchMarketplaceEpochs']),

    async onCreated() {
      await this.refetchData(false);
      this.subscribeToCascadeMarketplaceNotificationsChannel();
    },

    async fetchOrders(epochNumber) {
      try {
        this.isLoadingOrders = true;
        const response = await http.get(`subscription_marketplace_orders/private_index/${epochNumber}`);
        this.orders = response.data.subscription_marketplace_orders;
      } catch (error) {
        console.error(error);
      }
      finally {
        this.isLoadingOrders = false;
      }
    },

    epochSelected(epoch) {
      if (this.selectedEpoch === null) {
        this.selectedEpoch = epoch;
      } else {
        this.fetchOrders(epoch);
      }
    },

    async refetchData(force) {
      await this.fetchMarketplaceEpochs({ forceRefresh: force });
      await this.fetchOrders(this.currentMarketplaceEpoch.epoch);
    },

    async onOrderCancelled(_order) {
      await this.refetchData(true);
    },

    async onOrderDeleted(_uuid) {
      await this.refetchData(true);
    },

    subscribeToCascadeMarketplaceNotificationsChannel() {
      const consumer = createConsumer();
      const _this = this;

      consumer.subscriptions.create("CascadeMarketplaceNotificationsChannel", {
        connected() {
          // console.log("Connected to CascadeMarketplaceNotificationsChannel");
        },

        disconnected() {
          // console.log("Disconnected from CascadeMarketplaceNotificationsChannel");
        },

        async received(_data) {
          await _this.refetchData(true);
        }
      });
    },
  },
};
</script>
