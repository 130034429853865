import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    isLoadingOrders: false,
    isLoadingPublicOrderBook: false,
  },

  getters: {
    isLoadingOrders: state => state.isLoadingOrders,
    isLoadingPublicOrderBookOrders: state => state.isLoadingPublicOrderBook,
  },

  mutations: {
    SET_LOADING_ORDERS(state, isLoadingOrders) {
      state.isLoadingOrders = isLoadingOrders
    },

    SET_LOADING_PUBLIC_ORDER_BOOK_ORDERS(state, isLoadingPublicOrderBook) {
      state.isLoadingPublicOrderBook = isLoadingPublicOrderBook
    }
  },

  actions: {
    async fetchSubscriptionsOrders({ commit }, subscriptionsOrdersParams) {
      try {
        commit('SET_LOADING_ORDERS', true);

        const response = await http.get('subscription_marketplace_orders', {
          params: subscriptionsOrdersParams
        });

        return response.data.subscription_marketplace_orders;
      }
      finally {
        commit('SET_LOADING_ORDERS', false);
      }
    },

    // async fetchPublicOrderBookOrders({ commit }, epochNumber) {
    //   try {
    //     commit('SET_LOADING_PUBLIC_ORDER_BOOK_ORDERS', true);
    //     const response = await http.get(`subscription_marketplace_orders/public_order_book/${epochNumber}`);
    //     return response.data.subscription_marketplace_orders;
    //   }
    //   finally {
    //     commit('SET_LOADING_PUBLIC_ORDER_BOOK_ORDERS', false);
    //   }
    // }
  },

}
