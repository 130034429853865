<template>
  <div>
    <b-table :items="configurations" :fields="fields" small responsive>
      <template #cell(value)="data">

        <b-form-input
          v-if="data.item.editing"
          v-model="data.item.value"
          @keyup.enter="updateConfiguration(data.item)"
          @keyup.esc="cancelEdit(data.item)"
          :ref="'input-' + data.item.uuid"
        />
        <transition name="fade" mode="out-in">
          <span v-if="!data.item.editing">{{ data.item.value }}</span>
        </transition>

        <small class="text-danger" v-if="data.item.error">{{ data.item.error }}</small>
      </template>

      <template #row-details="row">
        <p class="fs--1 m-0 text-muted px-3">{{ row.item.description }}</p>
      </template>

      <template #cell(actions)="data">
        <transition name="fade" mode="out-in">
          <b-button
            v-if="!data.item.editing && data.item.key !== 'last_mock_epoch_info_run_at'"
            size="sm"
            variant="primary"
            @click="editConfiguration(data.item)"
          >
            Edit
          </b-button>

          <div v-if="data.item.editing" class="btn-group">
            <b-button
              size="sm"
              variant="success"
              @click="updateConfiguration(data.item)"
            >
              Save
            </b-button>
            <b-button
              size="sm"
              variant="danger"
              @click="cancelEdit(data.item)"
            >
              Cancel
            </b-button>
          </div>
        </transition>
      </template>
    </b-table>

    <b-modal
      id="confirm-update-modal"
      ref="confirmUpdateModal"
      title="Confirm Update"
      @ok="confirmUpdate"
    >
      Are you sure you want to update this configuration?
    </b-modal>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'MarketplaceConfigurationsTable',

  data() {
    return {
      configurations: [],
      fields: [
        { key: 'key', label: 'Key' },
        { key: 'value', label: 'Value' },
        {
          key: 'actions',
          label: 'Actions',
          thStyle: { width: '130px' },
          tdStyle: { width: '130px' },
        },
      ],
      itemToUpdate: null,
    };
  },

  created() {
    this.fetchConfigurations();
  },

  methods: {
    fetchConfigurations() {
      http.get('/marketplace_configurations').then((response) => {
        this.configurations = response.data.marketplace_configurations.map((config) => ({
          ...config,
          editing: false,
          error: null,
          _showDetails: true
        }));
      });
    },

    editConfiguration(item) {
      item.editing = true;
      item.originalValue = item.value; // Store original value in case of cancel

      this.$nextTick(() => {
        const inputRef = this.$refs['input-' + item.uuid];
        if (inputRef && inputRef.focus) {
          inputRef.focus();
        }
      });
    },

    cancelEdit(item) {
      item.editing = false;
      item.value = item.originalValue;
      item.error = null;
    },

    updateConfiguration(item) {
      // Validate input
      if (item.value === '') {
        item.error = 'Value cannot be empty';
        return;
      }

      this.itemToUpdate = item;
      this.$refs.confirmUpdateModal.show();
    },

    confirmUpdate() {
      const item = this.itemToUpdate;
      http
        .put(`/marketplace_configurations/${item.uuid}`, {
          marketplace_configuration: { value: item.value },
        })
        .then(() => {
          item.editing = false;
          item.error = null;
          this.$bvToast.toast('Configuration updated successfully', {
            variant: 'default'
          });
        })
        .catch((error) => {
          item.error = error.data.errors?.value?.join(' ') || 'Update failed';
        });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>