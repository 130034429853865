<template>
  <div>
    <div v-if="isLoadingOrders" class="text-center my-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-else>
      <b-table
        :items="orders"
        :fields="fields"
        small
        responsive
        :tbody-tr-class="rowClass"
        id="public-order-book-table"
        @row-clicked="onRowClicked"
      >
        <template #cell(current)="data">
          <b-badge
            v-if="isCurrentSubscriptionOrder(data.item)"
            :variant="isAccepted(data.item) ? 'success' : 'danger'"
          >
            My order
          </b-badge>
        </template>
      </b-table>

      <update-order-modal
        v-if="selectedOrder && marketplaceEpochs.length"
        :order="selectedOrder"
        :marketplace-epochs="marketplaceEpochs"
        v-on:modal-hide="selectedOrder = null"
        v-on:order-deleted="onOrderDeleted"
        v-on:order-cancelled="onOrderCancelled"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateOrderModal from './update-order-modal.vue';

export default {
  name: 'PublicOrderBookTable',

  components: { updateOrderModal },

  props: {
    orders: {
      type: Array,
      required: true,
    },
    isLoadingOrders: {
      type: Boolean,
      required: true,
    },
    currentSubscriptionBidderId: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      fields: [
        { key: 'bidder_id', label: 'Bidder ID' },
        { key: 'current', label: '' },
        { key: 'requested_bandwidth', label: 'PPS' },
        { key: 'max_bid', label: 'Max Bid' },
      ],
      selectedOrder: null,
    };
  },

  computed: {
    ...mapGetters('marketplaceEpochs', ['marketplaceEpochs']),
  },

  methods: {
    rowClass(item) {
      return [
        this.acceptedRejectedClass(item),
        this.isOrderForCurrentSubscriptionClass(item),
      ]
        .filter(Boolean)
        .join(' ');
    },

    isCurrentSubscriptionOrder(item) {
      return item.bidder_id === this.currentSubscriptionBidderId;
    },

    isAccepted(item) {
      return item.allocations_for_epoch.length > 0;
    },

    acceptedRejectedClass(item) {
      if (this.isAccepted(item)) {
        return 'row-accepted';
      } else {
        return 'row-rejected';
      }
    },

    isOrderForCurrentSubscriptionClass(item) {
      return this.isCurrentSubscriptionOrder(item)
        ? 'highlight-current-subscription'
        : '';
    },

    onRowClicked(item) {
      if (this.isCurrentSubscriptionOrder(item)) {
        this.selectedOrder = item;
      }
    },

    onOrderDeleted(uuid) {
      this.$emit('order-deleted', uuid)
    },

    onOrderCancelled(order) {
      this.$emit('order-cancelled', order)
    },
  },
};
</script>

<style>
.row-accepted {
  background-color: #e6ffed;
}

.row-rejected {
  background-color: #ffe6e6;
}

.highlight-current-subscription {
  font-weight: bold;
}

.highlight-current-subscription:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* New styles for darker shades when both classes are present */
.row-accepted.highlight-current-subscription {
  background-color: #b2f2bb; /* Darker green */
}

.row-rejected.highlight-current-subscription {
  background-color: #ffb3b3; /* Darker red */
}
</style>
