<template>
  <div class="card">
    <div class="card-header">
      <span class="h3 float-start mr-2">Subscription Features</span>

      <span class="float-right">
        <b-button
          v-if="formDisabled && currentUser.is_admin"
          @click="enableSubscriptionForm"
          variant="secondary"
        >
          Edit
          <span class="ml-2 far fa-edit"></span>
        </b-button>
      </span>
    </div>

    <div class="card-body">
      <b-form v-on:submit.prevent="updateFeatures">

        <div class="row">
          <div class="col-xl-8 col-12">
            <div class="mb-3">
              <b-form-checkbox :disabled="formDisabled" size="lg" v-model="form.cascade_marketplace">
                Cascade marketplace
              </b-form-checkbox>
            </div>

            <b-form-group label="Cascade marketplace rate limit">
              <b-form-input :disabled="formDisabled" v-model="form.cascade_marketplace_rate_limit" />
            </b-form-group>

            <b-form-group label="Cascade marketplace bidder ID">
              <b-input-group>
                <b-form-input
                  :disabled="formDisabled"
                  v-model="form.cascade_bidder_id"
                  autocomplete="off"
                />
                <b-input-group-append>
                  <b-button @click="$_copyToClipboard(form.cascade_bidder_id)">
                    Copy
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="my-3" v-if="!formDisabled">
              <b-button type="submit" variant="primary">Save</b-button>

              <b-button variant="secondary" @click="undoFormChanges">
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import copyToClipboard from '@/mixins/copy-to-clipboard';
import http from '@/services/http';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      form: {
        cascade_marketplace: null,
        cascade_marketplace_rate_limit: 0,
        cascade_bidder_id: null
      },
      formDisabled: true,
      originalForm: {},
    }
  },

  mixins: [copyToClipboard],

  props: {
    subscription: { type: Object, required: true }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', ['currentUser'])
  },

  methods: {
    onCreated() {
      this.form.cascade_marketplace = this.subscription.cascade_marketplace;
      this.form.cascade_marketplace_rate_limit = this.subscription.cascade_marketplace_rate_limit || 0;
      this.form.cascade_bidder_id = this.subscription.cascade_bidder_id;
      this.setOriginalForm();
    },

    async updateFeatures() {
      try {
        await http.put(`/subscriptions/${this.subscription.uuid}/features`, { subscription: this.form });
        this.$emit('updated', this.form);
        this.$bvToast.toast('Updated subscription', { title: 'Updated', variant: 'default' });

        this.setOriginalForm();
        this.disableSubscriptionForm();
      } catch (error) {
        console.log(error);
        this.$bvToast.toast('Could not update subscription', {
          title: 'Update failed',
          variant: 'danger'
        });
      }
    },

    undoFormChanges() {
      this.form = Object.assign({}, this.originalForm);
      this.disableSubscriptionForm();
    },

    enableSubscriptionForm() {
      this.formDisabled = false;
    },

    disableSubscriptionForm() {
      this.formDisabled = true;
    },

    setOriginalForm() {
      this.originalForm = Object.assign({}, this.form);
    }
  }
}
</script>

<style>
.btn-outline-secondary.disabled.active {
  color: #ffffff;
  background-color: #748194;
  border-color: #748194;
}
</style>