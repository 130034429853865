<template>
  <b-modal
    id="end-order-modal"
    v-model="showEndOrderModal"
    title="End Order"
    hide-footer
    centered
    header-bg-variant="light"
    header-class="py-3"
    @hidden="onModalHidden"
  >
    <h3>End your long-running order?</h3>
    <b-form @submit.prevent="endOrder">
      <b-form-group
        label="Enter the last epoch your order will remain active for"
        label-for="ending-epoch"
      >
        <b-form-input
          id="ending-epoch"
          v-model.number="endingEpoch"
          required
          autocomplete="off"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" size="sm" :disabled="submittingEndOrder">
        Yes, end long-running order
      </b-button>
      <b-button variant="outline-secondary" size="sm" @click="showEndOrderModal = false">
        No, keep order
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '@/services/http'

export default {
  name: 'EndOrderModal',

  props: {
    order: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      showEndOrderModal: false,
      submittingEndOrder: false,
      endingEpoch: null,
    }
  },

  created() {
    this.endingEpoch = this.currentMarketplaceEpoch.epoch;
  },

  watch: {
    order(newOrder) {
      if (newOrder) {
        this.showEndOrderModal = true;
      }
    },

    'currentMarketplaceEpoch.epoch'(newEpoch) {
      console.log('newEpoch', newEpoch);
      this.endingEpoch = newEpoch;
    },
  },

  computed: {
    ...mapGetters('marketplaceEpochs', ['currentMarketplaceEpoch']),
  },

  methods: {
    async endOrder() {
      this.submittingEndOrder = true
      try {
        await http.put(`subscription_marketplace_orders/${this.order.uuid}/cancel`, {
          subscription_marketplace_order: {
            ending_epoch: this.endingEpoch
          }
        });
        this.$emit('order-cancelled');
        this.showEndOrderModal = false;
      } finally {
        this.submittingEndOrder = false;
      }
    },

    onModalHidden() {
      this.endingEpoch = this.currentMarketplaceEpoch.epoch;
      this.$emit('modal-hidden');
    },
  },
}
</script>