<template>
  <div>
    <b-alert
      v-if="errorMessage"
      show
      variant="danger"
    >
      {{ errorMessage }}
    </b-alert>

    <b-form @submit.prevent="submitOrder">
      <b-form-group label="Bandwidth (PPS)" label-for="requested-bandwidth">
        <b-form-input
          id="requested-bandwidth"
          v-model.number="order.requested_bandwidth"
          min="10"
          required
          autofocus
          autocomplete="off"
          placeholder="Minimum is 10"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.requested_bandwidth">
          {{ errors.requested_bandwidth }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-for="max-bid"
        label="Max price per 10 PPS per epoch (SOL)"
        description="Enter a price equal to or greater than the current asking price"
      >
        <b-form-input
          id="max-bid"
          v-model="order.max_bid"
          required
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.max_bid">
          {{ errors.max_bid }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Starting Epoch" label-for="starting-epoch" :description="startingEpochDescription">
        <b-form-input
          id="starting-epoch"
          v-model.number="order.beginning_marketplace_epoch_number"
          required
          :state="errors.beginning_marketplace_epoch ? false : null"
          placeholder="Enter the starting epoch"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.beginning_marketplace_epoch">
          {{ errors.beginning_marketplace_epoch }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button variant="primary" :disabled="isSubmitting" type="submit" class="mt-3">
        <span v-if="isSubmitting">
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </span>
        <span class="emboss" v-else>Submit</span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateOrderForm',

  props: {
    subscriptionUuid: {
      type: String,
      required: true,
    },

    orderBeingReplaced: {
      type: Object,
      default: null,
    },

    mode: {}
  },

  data() {
    return {
      isSubmitting: false,
      order: {
        requested_bandwidth: null,
        max_bid: null,
        beginning_marketplace_epoch_number: null,
        subscription_uuid: this.subscriptionUuid,
      },
      errors: {},
      errorMessage: null,
    };
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('marketplaceEpochs', [
      'marketplaceEpochs',
      'soonestEpochOpenForOrders',
    ]),

    startingEpochDescription() {
      if (this.mode === 'cancel-and-replace') {
        return "The earliest epoch that you can replace your order has been pre-filled";
      } else {
        return "The soonest epoch that you can submit an order for has been pre-filled";
      }
    },
  },

  methods: {
    async onCreated() {
      this.order.beginning_marketplace_epoch_number = this.soonestEpochOpenForOrders.epoch;

      if (this.soonestEpochOpenForOrders.current_available_bandwidth === 0) {
        const maxBid = Number(this.soonestEpochOpenForOrders.current_floor) + Number(this.soonestEpochOpenForOrders.price_incrementation);
        this.order.max_bid = maxBid.toFixed(2);
      } else {
        this.order.max_bid = this.soonestEpochOpenForOrders.current_floor;
      }

      if (this.orderBeingReplaced) {
        this.order.requested_bandwidth = this.orderBeingReplaced.requested_bandwidth;
      }
    },

    async submitOrder() {
      this.isSubmitting = true;
      this.errors = {};
      this.errorMessage = null;

      const createParams = { subscription_marketplace_order: this.order };
      if (this.orderBeingReplaced) {
        createParams.cancel_and_replace_order_uuid = this.orderBeingReplaced.uuid;
      }

      await http.post('subscription_marketplace_orders', createParams)
        .then((response) => {
          this.resetForm();
          this.$emit('order-submitted', response.data.subscription_marketplace_order);
        })
        .catch((error) => {
          this.$bvToast.toast('Could not submit order', {
            title: '',
            variant: 'danger'
          });

          if (error.data && error.data.errors) {
            this.processErrors(error.data.errors);
          } else {
            this.errorMessage = 'An error occurred while submitting the order.';
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    processErrors(errors) {
      this.errors = {};
      Object.keys(errors).forEach((field) => {
        this.errors[field] = errors[field][0];
      });
    },

    resetForm() {
      this.order.requested_bandwidth = null;
      this.order.max_bid = null;
      this.order.beginning_marketplace_epoch_number = null;
    },
  },
};
</script>
