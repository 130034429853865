<template>
  <div class="p-3 container-xxl">
    <div v-if="isLoading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <div class="mb-3">
        <router-link
          class="font-weight-bold"
          :to="{ name: 'editSubscription', params: { uuid: this.$route.params.uuid } }"
        >
          <b-icon scale="1.25" icon="arrow-left" class="mr-1 icon-hover" />
          Subscription
        </router-link>

        <div class="float-right mb-2">
          <router-link
            :to="{ name: 'marketplaceMetrics', params: { uuid: subscription.account.uuid, subscriptionUuid: subscription.uuid } }"
            class="btn btn-primary btn-sm float-right"
          >
            Cascade Marketplace Metrics
          </router-link>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-header bg-light">
          <h3 class="pt-3">Cascade Marketplace</h3>
          <p class="fs--1">
            Submit long-running limit orders for priority bandwidth on Solana through this subscription
          </p>
        </div>

        <div class="card-body">
          <div class="container-lg">
            <marketplace-alerts
              :subscriptions-orders="subscriptionsOrders"
              v-on:show-create-order-modal="onShowCreateOrderModal"
            />

            <div v-if="canSubmitNewOrder" class="col-lg-6">
              <create-order-form
                :subscription-uuid="subscription.uuid"
                v-on:order-submitted="onOrderSubmitted"
              />
            </div>
          </div>
        </div>
      </div>

      <subscriptions-orders-table
        v-if="subscriptionsOrders.length"
        :orders="subscriptionsOrders"
        v-on:show-end-order-modal="onShowEndOrderModal"
        v-on:show-cancel-and-replace-order-modal="onShowCancelAndReplaceOrderModal"
      />

      <update-order-modal
        v-if="selectedOrderToUpdate && marketplaceEpochs.length"
        :order="selectedOrderToUpdate"
        :marketplace-epochs="marketplaceEpochs"
        v-on:modal-hide="selectedOrderToUpdate = null"
        v-on:order-deleted="onOrderDeleted"
        v-on:order-cancelled="onOrderCancelled"
      />

      <b-modal
        id="create-order-modal"
        v-model="showCreateOrderModal"
        title="Submit Order"
        hide-footer
        centered
        header-bg-variant="light"
        header-class="py-3"
      >
        <create-order-form
          :order-being-replaced="orderBeingReplaced"
          :subscriptionUuid="subscription.uuid"
          v-on:order-submitted="onOrderSubmitted"
        />
      </b-modal>

      <b-modal
        id="cancel-and-replace-order-modal"
        v-model="showCancelAndReplaceOrderModal"
        title="Cancel & Replace Order"
        hide-footer
        centered
        header-bg-variant="light"
        header-class="py-3"
      >
        <create-order-form
          :order-being-replaced="orderBeingReplaced"
          :subscriptionUuid="subscription.uuid"
          mode="cancel-and-replace"
          v-on:order-submitted="onOrderCanceledAndReplaced"
        />
      </b-modal>

      <end-order-modal
        :order="selectedOrderToUpdateToEnd"
        v-on:modal-hidden="selectedOrderToUpdateToEnd = null"
        v-on:order-cancelled="onOrderCancelled"
      />

      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-header bg-light py-2">
              <div class="h4">Public Order Book</div>
              <p class="fs--1">Select an epoch on the right to filter orders for it</p>
            </div>
            <div class="card-body">
              <public-order-book-table
                v-if="currentMarketplaceEpoch"
                :orders="publicOrderBookOrders"
                :is-loading-orders="isLoadingOrders"
                :current-subscription-bidder-id="subscription.cascade_bidder_id"
                v-on:order-cancelled="onOrderCancelled"
                v-on:order-deleted="onOrderDeleted"
              />
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="sticky-top">
            <div class="card">
              <div class="card-header bg-light py-2">
                <div class="h4">Epochs</div>
              </div>
              <div class="card-body">
                <marketplace-epochs-table
                  v-on:epoch-selected="epochSelected"
                  :subscriptions-orders="subscriptionsOrders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters, mapActions } from 'vuex';
import { createConsumer } from '@rails/actioncable';

import marketplaceAlerts from './marketplace-alerts';
import createOrderForm from '@/components/pages/marketplace/create-order-form';
import publicOrderBookTable from '@/components/pages/marketplace/public-order-book-table';
import marketplaceEpochsTable from '@/components/pages/marketplace/marketplace-epochs-table';
import subscriptionsOrdersTable from '@/components/pages/marketplace/subscriptions-orders-table';
import updateOrderModal from './update-order-modal';
import endOrderModal from './end-order-modal';

export default {
  name: 'MarketplaceHome',

  components: {
    marketplaceAlerts,
    createOrderForm,
    publicOrderBookTable,
    marketplaceEpochsTable,
    subscriptionsOrdersTable,
    updateOrderModal,
    endOrderModal
  },

  data() {
    return {
      isLoading: false,
      isLoadingOrders: false,
      subscription: {},
      subscriptionsOrders: [],
      selectedEpoch: null,
      publicOrderBookOrders: [],
      showCreateOrderModal: false,
      showUpdateOrderModal: false,
      showCancelAndReplaceOrderModal: false,
      orderBeingReplaced: null,
      selectedOrderToUpdate: null,
      selectedOrderToUpdateToEnd: null,
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('marketplaceEpochs', [
      'marketplaceEpochs',
      'currentMarketplaceEpoch',
      'lastMarketplaceEpochOpenForOrders',
    ]),

    futureEpochAllocations() {
      return this.subscriptionsOrders.filter((order) => {
        return order.ending_marketplace_epoch_number > this.currentMarketplaceEpoch.epoch && order.allocations.length > 0;
      });
    },

    currentSubscriptionsOrder() {
      return this.subscriptionsOrders.find((order) => order.bidder_id === this.subscription.cascade_bidder_id);
    },

    canSubmitNewOrder() {
      if (this.currentSubscriptionsOrder && (this.currentSubscriptionsOrder.ending_marketplace_epoch_number === null || this.currentSubscriptionsOrder.ending_marketplace_epoch_number > this.lastMarketplaceEpochOpenForOrders.epoch)) {

        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    ...mapActions('marketplaceEpochs', ['fetchMarketplaceEpochs']),
    ...mapActions('subscriptionMarketplaceOrders', ['fetchSubscriptionsOrders']),

    async onCreated() {
      try {
        this.isLoading = true;

        await this.fetchSubscription();
        await this.fetchMarketplaceEpochs();
        await this.fetchPublicOrderBookOrders(this.currentMarketplaceEpoch.epoch);
        this.fetchSubscriptionsOrders({ subscription_uuid: this.subscription.uuid })
          .then((orders) => {
            this.subscriptionsOrders = orders;
          });

        this.subscribeToCascadeMarketplaceNotificationsChannel();
      }
      finally {
        this.isLoading = false;
      }
    },

    async fetchSubscription() {
      const response = await http.get(`/subscriptions/${this.$route.params.uuid}`);
      this.subscription = response.data.subscription;
    },

    async fetchPublicOrderBookOrders(epochNumber) {
      try {
        this.isLoadingOrders = true;
        const response = await http.get(`/subscription_marketplace_orders/public_index/${epochNumber}`);
        this.publicOrderBookOrders = response.data.subscription_marketplace_orders;
      }
      finally {
        this.isLoadingOrders = false;
      }
    },

    epochSelected(epoch) {
      if (this.selectedEpoch === null) {
        this.selectedEpoch = epoch;
      } else {
        this.fetchPublicOrderBookOrders(epoch);
      }
    },

    onShowCreateOrderModal(order) {
      this.orderBeingReplaced = order || null;
      this.showCreateOrderModal = true;
    },

    onShowCancelAndReplaceOrderModal(order) {
      this.orderBeingReplaced = order;
      this.showCancelAndReplaceOrderModal = true;
    },

    onShowUpdateOrderModal(order) {
      this.selectedOrderToUpdate = order;
    },

    onShowEndOrderModal(order) {
      this.selectedOrderToUpdateToEnd = order;
    },

    async onOrderSubmitted(order) {
      // Refetch to get updated marketplace epoch info since a new order was submitted
      await this.fetchMarketplaceEpochs({ forceRefresh: true });
      this.fetchSubscriptionsOrders({ subscription_uuid: this.subscription.uuid })
        .then((orders) => {
          this.subscriptionsOrders = orders;
        });

      this.showCreateOrderModal = false;
      this.epochSelected(order.beginning_marketplace_epoch_number);
    },

    async onOrderCanceledAndReplaced(order) {
      // Refetch to get updated marketplace epoch info since a new order was submitted
      await this.fetchMarketplaceEpochs({ forceRefresh: true });
      this.fetchSubscriptionsOrders({ subscription_uuid: this.subscription.uuid })
        .then((orders) => {
          this.subscriptionsOrders = orders;
        });

      this.showCancelAndReplaceOrderModal = false;
      this.epochSelected(order.beginning_marketplace_epoch_number);
    },

    async onOrderCancelled(_order) {
      await this.refetchData();
    },

    async onOrderDeleted(_uuid) {
      await this.refetchData();
    },

    async refetchData() {
      await this.fetchMarketplaceEpochs({ forceRefresh: true });
      await this.fetchPublicOrderBookOrders(this.currentMarketplaceEpoch.epoch);

      this.fetchSubscriptionsOrders({ subscription_uuid: this.subscription.uuid })
          .then((orders) => {
            this.subscriptionsOrders = orders;
          });
    },

    subscribeToCascadeMarketplaceNotificationsChannel() {
      const consumer = createConsumer();
      const _this = this;

      consumer.subscriptions.create("CascadeMarketplaceNotificationsChannel", {
        connected() {
          // console.log("Connected to CascadeMarketplaceNotificationsChannel");
        },

        disconnected() {
          // console.log("Disconnected from CascadeMarketplaceNotificationsChannel");
        },

        async received(_data) {
          await _this.refetchData();
        }
      });
    },
  },
};

</script>
