<template>
  <div class="container py-2">
    <b-alert
      v-if="error"
      variant="danger"
      dismissible
      @dismissed="error = null"
    >
      {{ error }}
    </b-alert>

    <div v-if="isLoading" class="text-center my-3">
      <b-spinner label="Loading epoch details..."></b-spinner>
    </div>

    <div v-else>
      <div class="mb-3">
        <router-link
          class="font-weight-bold"
          to="/admins/marketplace"
        >
          <b-icon scale="1.25" icon="arrow-left" class="mr-1 icon-hover" />
          Epochs
        </router-link>
      </div>

      <div class="card">
        <div class="card-header bg-light py-2">
          <h3>Marketplace Epoch {{ marketplaceEpoch.epoch }}</h3>
        </div>

        <div class="card-body mb-4">
          <div class="row">
            <div class="col-6">
              <update-marketplace-epoch-form :marketplaceEpoch="marketplaceEpoch" />
            </div>
          </div>

          <h4 class="py-3">Order Allocations</h4>
          <b-table
            :items="orderAllocations"
            :fields="fields"
            hover
            responsive
          >
            <template v-slot:cell(subscription)="data">
              <span>{{ data.item.subscription.uuid }}</span>
            </template>
            <template v-slot:cell(max_bid)="data">
              <span>{{ data.item.subscription_marketplace_order.max_bid }}</span>
            </template>
            <template v-slot:cell(order_status)="data">
              <b-badge :variant="statusVariant(data.item.subscription_marketplace_order.status)">
                {{ data.item.subscription_marketplace_order.status }}
              </b-badge>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import updateMarketplaceEpochForm from '@/components/pages/marketplace/update-marketplace-epoch-form';

export default {
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  components: {
    updateMarketplaceEpochForm,
  },

  data() {
    return {
      marketplaceEpoch: null,
      orderAllocations: [],
      isLoading: false,
      error: null,
      fields: [
        { key: 'subscription.name', label: 'Subscription', sortable: false },
        { key: 'subscription.cascade_bidder_id', label: 'Bidder ID', sortable: false },
        { key: 'bandwidth_allocated', label: 'Bandwidth Allocated', sortable: true },
        { key: 'price_per_unit', label: 'Price per Unit', sortable: true },
        { key: 'total_price', label: 'Total Price', sortable: true },
        { key: 'max_bid', label: 'Max Bid Amount', sortable: true },
        { key: 'order_status', label: 'Order Status', sortable: true },
      ],
    };
  },
  created() {
    this.fetchEpochDetails();
  },
  methods: {
    async fetchEpochDetails() {
      this.isLoading = true;
      this.error = null;

      const apiUrl = `/marketplace_epochs/${this.uuid}`;

      await http
        .get(apiUrl)
        .then((response) => {
          this.marketplaceEpoch = response.data.marketplace_epoch;
          this.orderAllocations = this.marketplaceEpoch.marketplace_order_allocations;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error) {
            this.error = error.response.data.error;
          } else {
            this.error = 'An error occurred while fetching epoch details.';
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    statusVariant(status) {
      switch (status) {
        case 'accepted':
          return 'success';
        case 'pending':
          return 'warning';
        case 'rejected':
          return 'danger';
        default:
          return 'secondary';
      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>