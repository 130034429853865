<template>
  <div>
    <b-alert v-if="hasNoActiveOrders" show variant="primary">
      This subscription has no active orders for priority bandwidth on the Cascade Marketplace. <b-link @click="$emit('show-create-order-modal')">Click here to create one</b-link>.
    </b-alert>

    <b-alert v-if="hasActiveAndValidOrder" show variant="primary">
      Your order for priority bandwidth on the Cascade Marketplace is active and valid. Check the public order book below to see where your bid stands compared to others.
    </b-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'marketplaceAlerts',

  props: ['subscriptionsOrders'],

  computed: {
    ...mapGetters('marketplaceEpochs', [
      'soonestEpochOpenForOrders',
      'currentMarketplaceEpoch',
    ]),

    hasNoActiveOrders() {
      return this.hasNoOrders || this.ordersThatHaveNotEnded.length === 0;
    },

    hasNoOrders() {
      return this.subscriptionsOrders.length === 0;
    },

    ordersThatHaveNotEnded() {
      return this.subscriptionsOrders.filter((order) => {
        return order.ending_marketplace_epoch_number === null ||
               order.ending_marketplace_epoch_number >= this.currentMarketplaceEpoch.epoch;
      });
    },

    hasActiveAndValidOrder() {
      if (this.ordersThatHaveNotEnded.length > 0) {
        return this.ordersThatHaveNotEnded.some((order) => {
          const allocationEpochs = order.allocations.map((allocation) => allocation.epoch_number);

          return allocationEpochs.includes(this.soonestEpochOpenForOrders.epoch) &&
                 allocationEpochs.includes(this.soonestEpochOpenForOrders.epoch + 1) &&
                 allocationEpochs.includes(this.soonestEpochOpenForOrders.epoch + 2);
        });
      } else {
        return false;
      }
    },
  },
}
</script>